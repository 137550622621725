import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-5">
      <Container>
        <Row className="border-top pt-3">
          <Col md={4} className="text-md-left text-center">
            <h5 style={{ textAlign: 'left', fontWeight: 'bold' }}>Calathea Travel</h5>
            <p style={{ textAlign: 'left' }}>
              Jl. Prof. DR. Satrio No.230, Kuningan, Karet Kuningan, <br />
              Kecamatan Setiabudi, Kota Jakarta Selatan, <br />
              Daerah Khusus Ibukota Jakarta 12940
            </p>
          </Col>
          <Col md={4} className="text-left contact-info">
            <h5 style={{ textAlign: 'left', fontWeight: 'bold' }}>Contact Us</h5>
            <div className="info-item">
              <strong>Email: </strong>
              <span>calatheatravel@gmail.com</span>
            </div>
            <div className="info-item">
              <strong>Telephone: </strong>
              <span>021-23092356</span>
            </div>
            <div className="info-item">
              <strong>Mobile: </strong>
              <span>081378049462</span>
            </div>
          </Col>

          <Col md={4} className="text-md-right text-left">
            <h5 style={{ textAlign: 'left', fontWeight: 'bold' }}>Social Media</h5>
            <p style={{}}>
              <a href="https://www.instagram.com/calatheatravel/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <span style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold' }}> Calathea Travel</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            <p>&copy; {currentYear} Calathea Travel. All rights reserved.</p>
            <a href="/Terms-and-Conditions" style={{marginRight: '1%'}}>Terms and Conditions</a>
            <a href="/policies">Policies</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
