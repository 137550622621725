import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
    return (
        <>
            <div className="relative">
                <h1 className="mt-6 text-center text-3xl text-gray-900">About Us</h1>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Welcome to Calathea Travel</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    At Calathea Travel, we believe that every journey should be a unique
                    and unforgettable experience. Founded by a team of passionate travelers
                    and seasoned industry experts, our mission is to bring you closer to the
                    world’s most beautiful destinations while providing exceptional service
                    and personalized itineraries.
                </p>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Our Story</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    Calathea Travel was born out of a shared love for exploration and a desire to
                    create memorable travel experiences. We started our journey with a simple idea:
                    to make travel accessible, enjoyable, and enriching for everyone. Over the years,
                    we have grown into a trusted travel partner for adventurers, families, and solo
                    travelers alike, offering tailored travel solutions that cater to diverse preferences
                    and needs.
                </p>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Our Services</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    We offer a wide range of travel services designed to make your trip seamless and enjoyable:
                </p>
                <ul className="list-disc list-inside max-w-[60%] mx-auto">
                    <li className="text-justify"><strong>Customized Travel Packages:</strong> Whether you're looking for a relaxing beach vacation, an adventurous mountain trek, or an immersive cultural experience, we create personalized itineraries that match your interests and budget.</li>
                    <li className="text-justify"><strong>Group Tours:</strong> Join one of our expertly curated group tours and explore new destinations with like-minded travelers. Our group tours are perfect for those who enjoy socializing and sharing experiences.</li>
                    <li className="text-justify"><strong>Luxury Travel:</strong> Indulge in the finest luxuries with our exclusive travel packages. From five-star accommodations to private guided tours, we ensure a premium experience.</li>
                    <li className="text-justify"><strong>Adventure Travel:</strong> For the thrill-seekers, we offer a variety of adventure travel options, including hiking, diving, and wildlife safaris.</li>
                    <li className="text-justify"><strong>Travel Consultation:</strong> Need help planning your trip? Our experienced travel consultants are here to provide expert advice and support, ensuring every detail of your journey is perfect.</li>
                </ul>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Our Commitment</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    At Calathea Travel, we are committed to responsible and sustainable travel. We strive to minimize our
                    environmental impact and support local communities in the destinations we visit. We believe that travel
                    should be a force for good, promoting cultural understanding and environmental stewardship.
                </p>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Why Choose Us?</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    <strong>Expert Knowledge:</strong> Our team of travel experts has in-depth knowledge of destinations around the
                    world, ensuring you get the best recommendations and insider tips.
                </p>
                <p className="text-justify max-w-[60%] mx-auto">
                    <strong>Personalized Service:</strong> We take the time to understand your preferences and tailor our services
                    to meet your unique needs.
                </p>
                <p className="text-justify max-w-[60%] mx-auto">
                    <strong>Reliable Support:</strong> From the moment you book with us until you return home, we provide 24/7 support
                    to ensure a smooth and stress-free travel experience.
                </p>
                <p className="text-justify max-w-[60%] mx-auto">
                    <strong>Trusted Partners:</strong> We work with reputable airlines, hotels, and local guides to provide you with
                    the highest quality services.
                </p>
                <h2 className="mt-2 text-center text-xl text-gray-800 font-bold">Join Us on Your Next Adventure</h2>
                <p className="text-justify max-w-[60%] mx-auto">
                    Discover the world with Calathea Travel and let us turn your travel dreams into reality. Whether you're planning
                    a quick getaway or a once-in-a-lifetime adventure, we're here to make your journey extraordinary.
                </p>
            </div>
        </>
    );
};

export default About;
