import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsCondition = () => {
    return (
        <Container className="my-5">
            <h2 className="text-center mb-4">Terms & Conditions</h2>

            {/* Terms and Conditions Section */}
            <Row>
                <Col>
                    <h4>Terms and Conditions</h4>
                    <p>
                        Welcome to Calathea Travel. By accessing and using our services, you agree to comply with the following terms and conditions:
                    </p>
                    <ul>
                        <li><strong>Booking and Payments:</strong> All bookings must be paid in full at the time of reservation. We accept various payment methods including credit cards and online payment systems.</li>
                        <li><strong>Travel Documents:</strong> It is the responsibility of the traveler to ensure they have valid travel documents, including passports, visas, and any required vaccinations.</li>
                        <li><strong>Liability:</strong> Calathea Travel is not liable for any loss, injury, or damage incurred during travel. Travelers are advised to purchase travel insurance for protection.</li>
                        <li><strong>Changes to Itinerary:</strong> Calathea Travel reserves the right to make changes to the itinerary due to unforeseen circumstances. We will notify travelers of any significant changes as soon as possible.</li>
                        <li><strong>Privacy Policy:</strong> We value your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for detailed information.</li>
                        <li><strong>Governing Law:</strong> These terms and conditions are governed by the laws of the country in which Calathea Travel is based.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default TermsCondition;
