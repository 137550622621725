import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Policies = () => {
    return (
        <Container className="my-5">
            <h2 className="text-center mb-4">Policies</h2>

            {/* Policies Section */}
            <Row className="mb-5">
                <Col md={4}>
                    <h4>Return Policy</h4>
                    <p>
                        At Calathea Travel, we aim to provide the best travel experiences. However, if you are not satisfied with any service or product, please contact us within 30 days of purchase. Returns are evaluated on a case-by-case basis.
                    </p>
                </Col>
                <Col md={4}>
                    <h4>Refund Policy</h4>
                    <p>
                        If you are eligible for a refund, it will be processed within 7-10 business days. Refunds will be credited back to the original payment method used during the purchase. Please note that some services may have non-refundable components.
                    </p>
                </Col>
                <Col md={4}>
                    <h4>Cancellation Policy</h4>
                    <p>
                        You can cancel your bookings up to 24 hours before the scheduled departure time for a full refund. Cancellations made within 24 hours of the departure time may incur a cancellation fee. Please contact our customer service team for assistance with cancellations.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default Policies;
